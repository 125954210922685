import { render, staticRenderFns } from "./UploadTools.vue?vue&type=template&id=324b88e9&scoped=true&"
import script from "./UploadTools.vue?vue&type=script&lang=js&"
export * from "./UploadTools.vue?vue&type=script&lang=js&"
import style0 from "./UploadTools.vue?vue&type=style&index=0&id=324b88e9&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "324b88e9",
  null
  
)

export default component.exports